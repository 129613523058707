import React from 'react';

import {themeGet} from '@styled-system/theme-get';
import {FireworkCTAButtonClick} from 'src/analytics';
import {Anchor, FireworkCTA} from 'src/components/controls';
import {Box} from 'src/components/shared';
import {Typography} from 'src/components/shared/fireworkTypography';
import {EXPERIENCE} from 'src/routes/paths';
import {fonts} from 'src/styleguide/fireworkTypographyStyles';
import {fontWeights} from 'src/styleguide/typographyStyles';
import {experience as copy} from 'src/utils/copy';
import styled from 'styled-components/macro';

const {H2, H3} = Typography;

const StyledBox: typeof Box = styled(Box).attrs({
  className: 'membership-option',
  width: [null, null, null, 'calc(33% - 1.5rem)'],
  minHeight: '21.375rem',
  position: 'relative',
  textAlign: 'center',
  borderRadius: '1.25rem',
  mt: '3rem',
  px: '1.5rem',
  py: '3rem',
})`
  border: 1px solid ${themeGet('colors.cream')};
  cursor: pointer;

  &:hover {
    color: ${themeGet('colors.neonGreen')};
  }

  &:hover .firework-cta {
    background: ${themeGet('colors.neonGreen')};
    border-color: ${themeGet('colors.neonGreen')};
  }
`;

const IconContainer: typeof Box = styled(Box).attrs({
  mt: '1.5rem',
})`
  svg {
    margin: 0 auto;
  }
`;

const StyledH2: typeof H2 = styled(H2).attrs({
  color: 'neonGreen',
  fontSize: ['1.1875rem', null, null, ''],
  lineHeight: ['1.1875rem', null, null, ''],
  letterSpacing: '0.2rem',
  textTransform: 'uppercase',
  fontFamily: fonts.heading,
  fontWeight: fontWeights.extraBold,
  transition: 'color 250ms',
})``;

const StyledH3: typeof H3 = styled(H3).attrs({
  fontSize: ['1.875rem', null, null, ''],
  lineHeight: ['1.875rem', null, null, ''],
  fontFamily: fonts.primary,
  mt: '1.5rem',
  transition: 'color 250ms',
})``;

const StyledFireworkCTA: typeof FireworkCTA = styled(FireworkCTA).attrs({
  variant: 'cream',
  position: 'absolute',
  bottom: 0,
  left: '50%',
})`
  transform: translate(-50%, 50%);

  &:hover {
    color: ${themeGet('colors.darkGreen')};
  }
`;

type MembershipOptionProps = {
  index: number;
  heading: string;
  icon: any;
  iconModal: any;
  subHeading: string;
  description: string;
  stat?: any;
  quote: string;
  setModalActiveState: any;
};

const handleClick = (evt: MouseEvent, props: MembershipOptionProps) => {
  evt.preventDefault();
  evt.stopPropagation();

  props.setModalActiveState({active: true, idx: props.index});

  FireworkCTAButtonClick({
    category: 'experience.membership-options.learn-more',
    pageLocation: 'membershipOptions',
    sectionName: props.heading,
  });
};

export const MembershipOption: React.FC<MembershipOptionProps> = ({...props}) => {
  return (
    <StyledBox>
      <Anchor to={EXPERIENCE} onClick={(e: MouseEvent) => handleClick(e, props)}>
        <StyledH2>{props.heading}</StyledH2>

        <IconContainer>{props.icon}</IconContainer>

        <StyledH3>{props.subHeading}</StyledH3>

        <StyledFireworkCTA onClick={(e: MouseEvent) => handleClick(e, props)} to={EXPERIENCE}>
          {copy.membership_options.option_cta}
        </StyledFireworkCTA>
      </Anchor>
    </StyledBox>
  );
};

MembershipOption.defaultProps = {
  stat: false,
};
