import React from 'react';

import {themeGet} from '@styled-system/theme-get';
import {Box, FireworkContainer, Flex, Image} from 'src/components/shared';
import {Typography} from 'src/components/shared/fireworkTypography';
import {breakpoints} from 'src/styleguide/defaultTheme';
import {experience as copy} from 'src/utils/copy';
import styled from 'styled-components/macro';

const {H1, P1} = Typography;

const StyledFlex: typeof Flex = styled(Flex).attrs({
  backgroundColor: 'darkGreen',
  color: 'cream',
  flexDirection: ['column', null, null, 'row'],
  alignItems: 'center',
  mx: 'auto',
  px: ['1.5rem'],
  pb: ['1.5rem', null, null, '4.5rem'],
})``;

const TextContainer: typeof Box = styled(Box).attrs({
  width: ['100%', null, null, '60%', 'auto'],
})``;

const StyledH1: typeof H1 = styled(H1).attrs({
  fontSize: ['4.25rem', null, null, '5rem'],
  lineHeight: ['4.25rem', null, null, '5rem'],
})`
  span:nth-child(1) {
    margin-right: 0.75rem;
  }

  span:nth-child(2) {
    color: ${themeGet('colors.neonGreen')};
    font-style: italic;
  }

  span:nth-child(3) {
    display: block;
  }
`;

const StyledP1: typeof P1 = styled(P1).attrs({
  fontSize: ['1.3125rem', null, null, '1.5rem'],
  lineHeight: ['1.625rem', null, null, '1.8125rem'],
  mt: '1.5rem',
})``;

const ImageContainer: typeof Box = styled(Box).attrs({
  width: ['100%', null, null, '40%', 'auto'],
  maxWidth: ['100%', null, null, '39.5rem'],
  mt: ['4rem', null, null, 0],
  pl: [0, null, null, '1.5rem'],
})``;

export const Hero: typeof FireworkContainer = ({...props}) => {
  return (
    <StyledFlex maxWidth={props.containerMaxWidth}>
      <TextContainer>
        <StyledH1>
          {copy.hero.headingStrings.map((str, i) => (
            <span key={i}>{str}</span>
          ))}
        </StyledH1>

        <StyledP1>{copy.hero.description}</StyledP1>
      </TextContainer>

      <ImageContainer>
        <Image src="https://assets.chief.com/lohp/images/experience/hero.png" aria-label={copy.hero.ariaLabel} />
      </ImageContainer>
    </StyledFlex>
  );
};
