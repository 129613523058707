import React from 'react';

import {themeGet} from '@styled-system/theme-get';
import {Video} from 'src/components/former-chief-marketing-web';
import {Testimonial} from 'src/components/homepage/shared/Testimonial';
import {Box, FireworkContainer, Flex} from 'src/components/shared';
import {Typography} from 'src/components/shared/fireworkTypography';
import {breakpoints} from 'src/styleguide/defaultTheme';
import {experience as copy} from 'src/utils/copy';
import styled from 'styled-components/macro';

const {H2, P2} = Typography;

const StyledBox: typeof Box = styled(Box).attrs({
  backgroundColor: 'darkGreen',
  color: 'cream',
  px: ['1.5rem'],
  py: ['4.5rem'],
})`
  .testimonial span:nth-child(2) {
    color: ${themeGet('colors.neonGreen')};
    font-style: italic;
  }

  .testimonial span:nth-child(2) {
    margin-left: 0.75rem;
    margin-right: 0.75rem;
  }

  .testimonial:nth-child(2) span:nth-child(2) {
    margin-right: 0;
  }

  @media screen and (min-width: ${breakpoints[2]}) {
    .testimonial h2 {
      font-size: 3.125rem;
      line-height: 3.125rem;
    }

    .testimonial:nth-child(2) .inner-container {
      justify-content: flex-end;
    }

    .testimonial:nth-child(2) {
      margin-top: 6rem;
    }
  }
`;

export const Testimonials: typeof FireworkContainer = ({...props}) => {
  return (
    <StyledBox>
      {copy.testimonials.map((testimonial, i) => (
        <Testimonial key={i} {...testimonial} />
      ))}
    </StyledBox>
  );
};
