import React, {useState} from 'react';

import {themeGet} from '@styled-system/theme-get';
import {FireworkCTAClick} from 'src/analytics';
import {FireworkCTA} from 'src/components/controls';
import {OptionModal} from 'src/components/experience/MembershipOptions/OptionModal';
import {Box, FireworkContainer, Flex} from 'src/components/shared';
import {Typography} from 'src/components/shared/fireworkTypography';
import {APPLY} from 'src/routes/paths';
import {breakpoints} from 'src/styleguide/defaultTheme';
import {fonts} from 'src/styleguide/fireworkTypographyStyles';
import {fontWeights} from 'src/styleguide/typographyStyles';
import {experience as copy} from 'src/utils/copy';
import styled from 'styled-components/macro';

import {MembershipOption} from './MembershipOption';

const {H2, P2} = Typography;

const Wrapper: typeof Box = styled(Box).attrs({
  backgroundColor: 'midnightBlue',
  color: 'cream',
  px: '1.5rem',
  pt: '4.5rem',
})``;

const StyledH2: typeof H2 = styled(H2).attrs({
  fontSize: ['2.875rem', null, null, '3rem'],
  lineHeight: ['3rem', null, null, '3.125rem'],
})`
  span:nth-child(3) {
    color: ${themeGet('colors.neonGreen')};
    font-style: italic;
  }

  @media screen and (min-width: ${breakpoints[2]}) {
    span:nth-child(1) {
      display: block;
    }
  }
`;

const StyledP2: typeof P2 = styled(P2).attrs({
  maxWidth: ['20.875rem', null, null, '100%'],
  fontSize: '1.1875rem',
  lineHeight: '1.3125rem',
  mx: 'auto',
  mt: '3rem',
  textAlign: 'center',
})``;

const StyledFireworkCTA: typeof FireworkCTA = styled(FireworkCTA).attrs({
  variant: 'neonGreen',
  width: ['100%', null, null, 'auto'],
  mt: ['3rem', null, null, '1.5rem'],
})``;

export const MembershipOptions: typeof FireworkContainer = ({...props}) => {
  const [modalActiveState, setModalActiveState] = useState({active: false, idx: 0});

  return (
    <Wrapper>
      <Box maxWidth={props.containerMaxWidth} mx="auto">
        <Flex flexDirection={['column', null, null, 'row']} justifyContent="space-between">
          <StyledH2>
            {copy.membership_options.headingStrings.map((str, i) => (
              <span key={i}>{str}</span>
            ))}
          </StyledH2>

          <Box>
            <StyledFireworkCTA
              onClick={(e: MouseEvent) => FireworkCTAClick(e, 'experience.membership-options', APPLY)}
              to={APPLY}
            >
              {copy.membership_options.cta}
            </StyledFireworkCTA>
          </Box>
        </Flex>

        <StyledP2>{copy.membership_options.description}</StyledP2>

        <Flex flexDirection={['column', null, null, 'row']} justifyContent="space-between">
          {copy.membership_options.options.map((option, idx) => (
            <MembershipOption key={idx} index={idx} setModalActiveState={setModalActiveState} {...option} />
          ))}
        </Flex>

        <Box width="100%" height="1px" mt="4.5rem" backgroundColor="cream" />
      </Box>

      <OptionModal
        activeState={modalActiveState}
        setActiveState={setModalActiveState}
        options={copy.membership_options.options}
      />
    </Wrapper>
  );
};
