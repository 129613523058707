import React from 'react';

import {themeGet} from '@styled-system/theme-get';
import {Box, FireworkContainer, Flex} from 'src/components/shared';
import {Typography} from 'src/components/shared/fireworkTypography';
import {breakpoints} from 'src/styleguide/defaultTheme';
import {fonts} from 'src/styleguide/fireworkTypographyStyles';
import {fontWeights} from 'src/styleguide/typographyStyles';
import {experience as copy} from 'src/utils/copy';
import styled from 'styled-components/macro';

const {H2, P1, P2, CTA} = Typography;

const StyledFlex: typeof Flex = styled(Flex).attrs({
  color: 'cream',
  flexDirection: ['column', null, null, 'row'],
  mx: 'auto',
  px: ['1.5rem'],
  py: ['4.5rem'],
})``;

const StyledH2: typeof H2 = styled(H2).attrs({
  fontSize: ['2.875rem', null, null, '3rem'],
  lineHeight: ['3rem', null, null, '3.125rem'],
})`
  span:nth-child(2) {
    color: ${themeGet('colors.periwinkle')};
    font-style: italic;
    margin: 0 0.75rem;
  }
`;

const StyledP1: typeof P1 = styled(P1).attrs({
  maxWidth: '35.0625rem',
  mt: '1.5rem',
})``;

const StyledP2: typeof P2 = styled(P2).attrs({
  fontSize: '1.1875rem',
  lineHeight: '1.3125rem',
  mt: '0.75rem',
})``;

const StyledCTA: typeof CTA = styled(CTA).attrs({
  color: 'periwinkle',
  lineHeight: '1rem',
  fontFamily: fonts.heading,
  letterSpacing: '0.2rem',
  textTransform: 'uppercase',
})``;

const Perk: typeof Box = styled(Box).attrs({
  mt: '3rem',
})`
  @media screen and (min-width: ${breakpoints[2]}) {
    &:first-child {
      margin-top: 0;
    }
  }
`;

export const CommunityPerks: typeof FireworkContainer = ({...props}) => {
  return (
    <Box backgroundColor="midnightBlue">
      <StyledFlex maxWidth={props.containerMaxWidth}>
        <Box maxWidth="44.0625rem">
          <StyledH2>
            {copy.community_perks.headingStrings.map((str, i) => (
              <span key={i}>{str}</span>
            ))}
          </StyledH2>

          <StyledP1>{copy.community_perks.description}</StyledP1>
        </Box>

        <Box>
          {copy.community_perks.perks.map((perk, i) => {
            return (
              <Perk key={i}>
                <StyledCTA>{perk.heading}</StyledCTA>

                <Box mt="1.5rem">
                  {perk.perks.map((item, i) => (
                    <StyledP2 key={i}>{item}</StyledP2>
                  ))}
                </Box>
              </Perk>
            );
          })}
        </Box>
      </StyledFlex>
    </Box>
  );
};
