import React from 'react';

import {Video} from 'src/components/former-chief-marketing-web';
import {Box, FireworkContainer} from 'src/components/shared';
import styled from 'styled-components/macro';

const StyledBox: typeof Box = styled(Box).attrs({
  backgroundColor: 'darkGreen',
  mx: 'auto',
})`
  .chief-video {
    padding: 0 !important; // Important needed to override framed padding in the component
  }
`;

export const VideoSection: typeof FireworkContainer = ({...props}) => {
  return (
    <StyledBox maxWidth={props.containerMaxWidth}>
      <Video
        url="https://www.youtube.com/embed/6kYTbVzbu0I?si=_pKe599cDu_KeGtX"
        controls="1"
        poster=""
        framed="0"
        muted="0"
        loop="0"
        className="chief-video"
      />
    </StyledBox>
  );
};
