// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
// NOTE: come back and fix the className & onClick TS errors
import React, {useState, useEffect, useRef, forwardRef} from 'react';

import {themeGet} from '@styled-system/theme-get';
import {motion, MotionProps, useAnimation} from 'framer-motion';
import {FireworkCTAButtonClick} from 'src/analytics';
import {Button} from 'src/components/controls';
import {Box, BoxProps, Flex} from 'src/components/shared';
import {Typography} from 'src/components/shared/fireworkTypography';
import {breakpoints} from 'src/styleguide/defaultTheme';
import {fonts} from 'src/styleguide/fireworkTypographyStyles';
import {fontWeights} from 'src/styleguide/typographyStyles';
import {zIndices} from 'src/styleguide/zIndices';
import {Icon, Icons} from 'src/svgs';
import {experience as copy} from 'src/utils/copy';
import {MobileOnly, TabletUp} from 'src/utils/responsive';
import {swipePower, wrapIndex} from 'src/utils/slider';
import styled, {keyframes} from 'styled-components/macro';

const {H2, H3, P2} = Typography;

export type OptionModalProps = {options: any[]; activeState: any; setActiveState: any; boxProps?: BoxProps};
type OptionProps = {option: any; active?: boolean};

const Wrapper: typeof Box = styled(Box).attrs({
  backgroundColor: 'rgba(0, 0, 0, 0.5)',
  width: '100%',
  height: '100%',
  position: 'fixed',
  top: 0,
  left: 0,
  zIndex: zIndices.modal,
})`
  opacity: 0;
  visibility: hidden;
  transition: opacity 500ms ease, visibility 500ms ease;

  &.active {
    opacity: 1;
    visibility: visible;
  }
`;

const Container: typeof Box = styled(Box).attrs({
  backgroundColor: 'cream',
  color: 'midnightBlue',
  width: 'calc(100% - 3rem)',
  maxWidth: '65.0625rem',
  minHeight: '29.25rem',
  maxHeight: 'calc(100% - 3rem)',
  mx: 'auto',
  padding: ['3rem 1.5rem', null, null, '3rem'],
  borderRadius: '1.25rem',
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  overflowY: ['scroll', null, null, 'visible'],
})``;

const MotionContainer = styled(motion.div)`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
`;

const Option = styled(motion.div).attrs({
  role: 'tabpanel',
  'aria-roledescription': 'slide',
})`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  flex-shrink: 0;
  flex-grow: 0;

  svg {
    margin: 0 auto;
  }
`;

const NavButton = styled(Button).attrs({
  variant: 'icon',
  className: 'nav-button',
  alignItems: 'center',
  justifyContent: 'center',
  width: '2.75rem',
  height: '2.75rem',
  borderRadius: '62.5rem',
  bg: 'tan',
  position: 'absolute',
  top: 0,
  bottom: 0,
  zIndex: 2,
  my: 'auto',
  border: '0',
  display: ['none', null, null, 'flex'],
})`
  background-color: ${props => themeGet('colors.tan')};
  cursor: pointer;
  transition: background-color 200ms ease;

  :hover:not(:disabled) {
    background-color: ${themeGet('colors.periwinkle')};
  }

  :disabled {
    opacity: 0.25;
    cursor: not-allowed;
  }
`;

const PageDot = styled.div<{active: boolean}>`
  width: 0.625rem;
  height: 0.625rem;
  border: 1px solid ${themeGet('colors.midnightBlue')};
  border-radius: 62.5rem;
  background-color: ${props => (props.active ? themeGet('colors.midnightBlue') : 'transparent')};
  margin: 0 0.25rem;
`;

const fadeIn = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;

const CloseContainer: typeof Box = styled(Box).attrs({
  position: 'absolute',
  top: '1rem',
  right: '1.5rem',
  zIndex: 101,
})`
  cursor: pointer;
`;

const InfoContainer: typeof Box = styled(Box).attrs({
  textAlign: 'center',
  pr: [null, null, null, '1.5rem'],
  position: 'relative',
})`
  flex: 0 0 20rem;

  @media screen and (min-width: ${breakpoints[2]}) {
    border-right: 1px solid ${themeGet('colors.midnightBlue')};
  }
`;

const QuoteContainer: typeof Box = styled(Box).attrs({
  mt: ['3rem', null, null, 0],
  pl: [null, null, null, '1.5rem'],
})``;

const OptionH2: typeof H2 = styled(H2).attrs({
  fontSize: ['1rem', null, null, '1.1875rem'],
  lineHeight: ['1rem', null, null, '1.1875rem'],
  letterSpacing: '0.2rem',
  textTransform: 'uppercase',
  fontFamily: fonts.heading,
  fontWeight: fontWeights.extraBold,
})``;

const OptionH3: typeof H3 = styled(H3).attrs({
  fontSize: '1.375rem',
  lineHeight: '1.375rem',
  mt: '3rem',
})``;

const OptionP2: typeof P2 = styled(P2).attrs({
  fontSize: '1.0625rem',
  lineHeight: '1.3125rem',
  mt: '1.5rem',
})``;

const StatH2: typeof H2 = styled(H2).attrs({
  fontSize: ['6.1875rem', null, null, '9.375rem'],
  lineHeight: ['6.1875rem', null, null, '9.375rem'],
  fontStyle: 'italic',
})``;

const StatH3: typeof H3 = styled(H3).attrs({
  fontSize: ['1.875rem', null, null, '2rem'],
  lineHeight: ['1.875rem', null, null, '2rem'],
  ml: [null, null, null, '1.5rem'],
})`
  span:nth-child(2) {
    font-style: italic;
    margin-left: 0.75rem;
    margin-right: 0.5rem;
  }
`;

const QuoteP2: typeof OptionP2 = styled(OptionP2).attrs({
  fontWeight: fontWeights.bold,
  mt: 0,
  position: 'relative',
})`
  &:after {
    content: ' ';
    background-color: ${themeGet('colors.midnightBlue')};
    width: 100%;
    height: 1px;
    position: absolute;
    top: 50%;
    left: 0;
    z-index: 0;
    display: none;
  }

  @media screen and (min-width: ${breakpoints[2]}) {
    &:after {
      display: block;
    }
  }

  span {
    position: relative;
    background-color: ${themeGet('colors.cream')};
    padding-right: 1.5rem;
    z-index: 1;
  }
`;

const QuoteH3: typeof H3 = styled(H3).attrs({
  fontSize: ['1.875rem', null, null, '2rem'],
  lineHeight: ['1.875rem', null, null, '2rem'],
  mt: '1.5rem',
})``;

const OptionSlide = forwardRef<HTMLDivElement, OptionProps>(({option, active}, ref) => {
  return (
    <Option ref={ref}>
      <Flex flexDirection={['column', null, null, 'row']} justifyContent="space-between" alignItems="flex-start">
        <InfoContainer>
          <OptionH2>{option.heading}</OptionH2>

          <Box mt="3rem">{option.iconModal}</Box>

          <OptionH3>{option.subHeading}</OptionH3>

          <OptionP2>{option.description}</OptionP2>
        </InfoContainer>

        <Box display={[null, null, null, 'none']} width="100%" height="1px" mt="3rem" backgroundColor="midnightBlue" />

        <QuoteContainer>
          {option?.stat?.stat && (
            <Flex flexDirection={['column', null, null, 'row']} mb={['1.5rem', null, null, 0]}>
              <StatH2>{option.stat.stat}</StatH2>

              <StatH3>
                {option.stat.descriptionStrings.map((str, i) => (
                  <span key={i}>{str}</span>
                ))}
              </StatH3>
            </Flex>
          )}

          <Box>
            <QuoteP2>
              <span>{copy.membership_options.option_quote_heading}</span>
            </QuoteP2>

            <QuoteH3>{option.quote}</QuoteH3>
          </Box>
        </QuoteContainer>
      </Flex>
    </Option>
  );
});

const transition = {type: 'spring', mass: 0.25, bounce: 0, stiffness: 1000, damping: 50};
const dragTransition = {bounceDamping: transition.damping, bounceStiffness: transition.stiffness};

export const OptionModal: React.FC<OptionModalProps> = ({options, activeState, setActiveState, boxProps}) => {
  const [index, setIndex] = useState(0);
  const parentRef = useRef<HTMLDivElement>(null);
  const animation = useAnimation();
  const indexRef = useRef(0);

  const handleNavigateLeft = async () => {
    await animation.stop();
    if (parentRef.current) {
      indexRef.current = index - 1;
      await animation.start({
        x: parentRef.current.getBoundingClientRect().width,
        transition,
        pointerEvents: 'none',
      });
    }
    setIndex(index - 1);
  };

  const handleNavigateRight = async () => {
    await animation.stop();
    if (parentRef.current) {
      indexRef.current = index + 1;
      await animation.start({
        x: -parentRef.current.getBoundingClientRect().width,
        transition,
        pointerEvents: 'none',
      });
    }
    setIndex(index + 1);
  };

  const handleKeyDown = React.useCallback(
    (event: KeyboardEvent) => {
      if (event.key === 'ArrowRight') {
        handleNavigateRight();
      } else if (event.key === 'ArrowLeft') {
        handleNavigateLeft();
      }
    },
    [options, index, setIndex],
  );

  const handleDragEnd: MotionProps['onDragEnd'] = async (evt, {offset}) => {
    if (parentRef.current) {
      const rect = parentRef.current.getBoundingClientRect();
      const power = swipePower(offset.x, rect.width);
      if (power > 10) {
        await handleNavigateLeft();
      } else if (power < -10) {
        await handleNavigateRight();
      }
    }
  };

  const handleClose = (evt: MouseEvent) => {
    setActiveState({active: false, idx: 0});

    FireworkCTAButtonClick({
      category: 'experience.membership-options.modal.close',
      pageLocation: 'membershipOptions',
      sectionName: 'Modal',
    });
  };

  useEffect(() => {
    parentRef.current?.addEventListener('keydown', handleKeyDown);

    return () => {
      parentRef.current?.removeEventListener('keydown', handleKeyDown);
    };
  }, [handleKeyDown]);

  useEffect(() => {
    setIndex(activeState.idx);
  }, [activeState]);

  const prevIndex = wrapIndex(0, options.length, index - 1);
  const currentIndex = wrapIndex(0, options.length, index);
  const nextIndex = wrapIndex(0, options.length, index + 1);

  return (
    <Wrapper {...boxProps} className={activeState.active ? 'active' : ''} aria-roledescription="carousel">
      <Container>
        <CloseContainer onClick={handleClose}>
          <Icon icon={Icons.close} aria-label="Close" color="midnightBlue" />
        </CloseContainer>

        <Box overflow="hidden">
          <MotionContainer
            key={index}
            drag="x"
            layout
            dragConstraints={{left: 0, right: 0}}
            animate={animation}
            dragMomentum={false}
            dragDirectionLock
            onDragEnd={handleDragEnd}
            whileTap={{cursor: 'grabbing'}}
            dragTransition={dragTransition}
          >
            <OptionSlide key={prevIndex} option={options[prevIndex]} />
            <OptionSlide key={index} ref={parentRef} option={options[currentIndex]} active />
            <OptionSlide key={nextIndex} option={options[nextIndex]} />
          </MotionContainer>
        </Box>

        <NavButton left="-1.375rem" onClick={handleNavigateLeft}>
          <Box size="1.25rem" transform="rotate(180deg)">
            <Icon icon={Icons.chevronRightLarge} aria-label="Left arrow" color="midnightBlue" />
          </Box>
        </NavButton>
        <NavButton right="-1.375rem" onClick={handleNavigateRight}>
          <Box size="1.25rem">
            <Icon icon={Icons.chevronRightLarge} aria-label="Right arrow" color="midnightBlue" />
          </Box>
        </NavButton>

        <TabletUp>
          <Flex
            position="absolute"
            left="0"
            bottom="1.5rem"
            width="100%"
            alignItems="center"
            justifyContent="center"
            zIndex={1}
          >
            {options.map((option, optionIndex) => (
              <PageDot key={option?._key} active={currentIndex === optionIndex} />
            ))}
          </Flex>
        </TabletUp>
      </Container>

      <MobileOnly>
        <Flex
          position="fixed"
          left="0"
          bottom="3rem"
          width="100%"
          alignItems="center"
          justifyContent="center"
          zIndex={1}
        >
          {options.map((option, optionIndex) => (
            <PageDot key={option?._key} active={currentIndex === optionIndex} />
          ))}
        </Flex>
      </MobileOnly>
    </Wrapper>
  );
};

OptionModal.defaultProps = {
  activeState: {active: false, idx: 0},
  boxProps: {},
};
