import React from 'react';

import {themeGet} from '@styled-system/theme-get';
import {FireworkCTAClick} from 'src/analytics';
import {Anchor, FireworkCTA} from 'src/components/controls';
import {Box, FireworkContainer, Flex} from 'src/components/shared';
import {Typography} from 'src/components/shared/fireworkTypography';
import {APPLY} from 'src/routes/paths';
import {breakpoints} from 'src/styleguide/defaultTheme';
import {fonts} from 'src/styleguide/fireworkTypographyStyles';
import {fontWeights} from 'src/styleguide/typographyStyles';
import {experience as copy} from 'src/utils/copy';
import styled from 'styled-components/macro';

const {H2, P2} = Typography;

const Wrapper: typeof Box = styled(Box).attrs({
  backgroundColor: 'darkGreen',
  px: '1.5rem',
  py: ['3rem', null, null, '4.5rem'],
})``;

const StyledBox: typeof Box = styled(Box).attrs({
  width: '100%',
  backgroundColor: 'neonGreen',
  borderRadius: '1.25rem',
  mx: 'auto',
  padding: ['3rem 3rem 1.5rem', null, null, '3rem'],
})``;

const StyledH2: typeof H2 = styled(H2).attrs({
  color: 'darkGreen',
  fontSize: ['2.875rem', null, null, '3rem'],
  lineHeight: ['3rem', null, null, '3.125rem'],
})`
  span:nth-child(2) {
    font-style: italic;
    margin-top: 0.75rem;
    display: block;
  }

  @media screen and (min-width: ${breakpoints[2]}) {
    span:nth-child(2) {
      display: inline-block;
      margin-top: 0;
      margin-left: 0.75rem;
    }
  }
`;

const StyledFireworkCTA: typeof FireworkCTA = styled(FireworkCTA).attrs({
  variant: 'darkGreen',
  width: ['100%', null, null, 'auto'],
  mt: ['4.5rem', null, null, '0.5rem'],
})``;

export const InvestSection: typeof FireworkContainer = ({...props}) => {
  return (
    <Wrapper>
      <Anchor mx="auto" to={APPLY}>
        <StyledBox maxWidth={props.containerMaxWidth}>
          <Flex width="100%" flexDirection={['column', null, null, 'row']} justifyContent="space-between">
            <StyledH2>
              {copy.invest.headingStrings.map((str, i) => (
                <span key={i}>{str}</span>
              ))}
            </StyledH2>

            <Box>
              <StyledFireworkCTA
                onClick={(e: MouseEvent) => FireworkCTAClick(e, 'experience.invest', APPLY)}
                to={APPLY}
              >
                {copy.invest.cta}
              </StyledFireworkCTA>
            </Box>
          </Flex>
        </StyledBox>
      </Anchor>
    </Wrapper>
  );
};
