import React from 'react';

import {themeGet} from '@styled-system/theme-get';
import {FireworkCTAClick} from 'src/analytics';
import {FireworkCTA} from 'src/components/controls';
import {Box, FireworkContainer, Flex} from 'src/components/shared';
import {Typography} from 'src/components/shared/fireworkTypography';
import {APPLY, MEMBERSHIP_CRITERIA} from 'src/routes/paths';
import {fonts} from 'src/styleguide/fireworkTypographyStyles';
import {fontWeights} from 'src/styleguide/typographyStyles';
import {Icon, Icons} from 'src/svgs';
import {experience as copy} from 'src/utils/copy';
import styled from 'styled-components/macro';

const {H2, P2} = Typography;

const Wrapper: typeof Box = styled(Box).attrs({
  backgroundColor: 'darkGreen',
  pt: [null, null, null, '3rem'],
  pb: ['4.5rem', null, null, '6rem'],
})``;

const StyledFlex: typeof Flex = styled(Flex).attrs({
  backgroundColor: 'lightGrey',
  flexDirection: ['column', null, null, 'row'],
  justifyContent: 'space-between',
  position: 'relative',
  mx: 'auto',
  px: [0, null, null, '3rem'],
  borderRadius: [0, null, null, '1.25rem'],
})``;

const TextContainer: typeof Box = styled(Box).attrs({
  order: [1, null, null, 0],
  color: 'midnightBlue',
  width: [null, null, null, 'calc(60% - 1.5rem)'],
  flexBasis: [null, null, null, 'calc(60% - 1.5rem)'],
  mt: ['1.5rem', null, null, 0],
  mx: ['1.5rem', null, null, 0],
  padding: ['0 0 3rem 0', null, null, '3rem 1.5rem 3rem 0'],
})``;

const IconContainer: typeof Box = styled(Box).attrs({
  order: [0, null, null, 1],
  width: [null, null, null, '40%'],
  position: 'relative',
  mt: ['-1.5rem'],
})`
  svg {
    width: 100%;
  }
`;

const RateContainer: typeof Box = styled(Box).attrs({
  color: 'neonGreen',
  textAlign: 'center',
  mt: ['3rem', null, null, '4.5rem'],
  mx: 'auto',
  px: '1.5rem',
})`
  .firework-cta {
    margin-top: 1.5rem;
  }
`;

const StyledFireworkCTA: typeof FireworkCTA = styled(FireworkCTA).attrs({
  width: ['100%', null, null, 'auto'],
  mt: ['3rem', null, null, '1.5rem'],
})``;

const StyledH2: typeof H2 = styled(H2).attrs({
  fontSize: ['2.875rem', null, null, '3rem'],
  lineHeight: ['3rem', null, null, '3.125rem'],
})``;

const StyledP2: typeof P2 = styled(P2).attrs({
  fontSize: '1.0625',
  lineHeight: '1.3125rem',
  mt: '1.5rem',
})``;

const Criteria: typeof StyledP2 = styled(StyledP2).attrs({
  width: '50%',
  fontWeight: fontWeights.bold,
  mt: '0.75rem',
})``;

export const MembershipCriteria: typeof FireworkContainer = ({...props}) => {
  return (
    <Wrapper>
      <StyledFlex maxWidth={props.containerMaxWidth}>
        <TextContainer>
          <StyledH2>{copy.membership_criteria.heading}</StyledH2>

          <StyledP2>{copy.membership_criteria.description}</StyledP2>

          <Flex flexDirection="row" flexWrap="wrap">
            {copy.membership_criteria.criteria.map((criteria, i) => (
              <Criteria key={i}>{criteria}</Criteria>
            ))}
          </Flex>

          <StyledFireworkCTA
            variant="midnightBlue"
            onClick={(e: MouseEvent) => FireworkCTAClick(e, 'experience.membership-criteria', MEMBERSHIP_CRITERIA)}
            to={MEMBERSHIP_CRITERIA}
          >
            {copy.membership_criteria.cta}
          </StyledFireworkCTA>
        </TextContainer>

        <IconContainer>
          <Icon icon={Icons.membershipCriteria} aria-label="Membership Criteria" />
        </IconContainer>
      </StyledFlex>

      {/*<RateContainer>
        <StyledH2>{copy.membership_criteria.membership_rate}</StyledH2>

        <StyledFireworkCTA variant="neonGreen" to={APPLY}>
          {copy.membership_criteria.membership_rate_cta}
        </StyledFireworkCTA>
      </RateContainer>*/}
    </Wrapper>
  );
};
