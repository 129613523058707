import React from 'react';

import {Box, FireworkContainer, ImageCarousel as ImageCarouselComponent} from 'src/components/shared';

const images = [
  {
    image: {
      asset: {
        url: 'https://assets.chief.com/lohp/images/experience/carousel/1.jpg',
      },
    },
  },
  {
    image: {
      asset: {
        url: 'https://assets.chief.com/lohp/images/experience/carousel/2.jpg',
      },
    },
  },
  {
    image: {
      asset: {
        url: 'https://assets.chief.com/lohp/images/experience/carousel/3alt.png',
      },
    },
  },
  {
    image: {
      asset: {
        url: 'https://assets.chief.com/lohp/images/experience/carousel/4.jpg',
      },
    },
  },
];

export const ImageCarousel: typeof FireworkContainer = ({...props}) => {
  return (
    <Box backgroundColor="cream">
      <ImageCarouselComponent images={images} autorotate={true} />
    </Box>
  );
};
